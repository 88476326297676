export const welcomeText = "Welcome to Paddocks 2.0";

export const ROUTE_LABELS = {
  HOME: "Home",
  LOGIN: "Login",
  COMPANIES: "Companies",
  VEHICLES: "Vehicles",
  CLAIMS: "Claims",
  UNDERWRITING: "Underwriting",
  FINANCE: "Finance",
  USERS: "Users",
  DASHBOARD: "Dashboard",
  BATCH: "Batch",
};

export const FINANCE_TAB_LABELS = {
  CUSTOMERS: "customers",
  PRODUCTS: "products",
  INVOICES: "invoices",
  PRODUCT_OFFERINGS: "product offerings",
};

export const COMPANY = {
  TITLE: "Company",
  QUOTE: {
    DISCOUNT_TITLE: "Add Discount",
    DISCOUNT_LABEL: "Discount",
    DETAILS: "Quote Details",
    CHANGE: "Change Discount",
    ADD: "Add Discount",
    QUANTITY: "Quantity",
    QTY: "Qty",
    TOTAL: "Total",
  },
  CURRENT_STATUS: "Current Status",
  DOCUMENTS: {
    TITLE: "Documents",
    DOWNLOAD: "Document Download",
    DOWNLOAD_READY: "Download Ready",
    DOWNLOAD_ERROR: "Download Error",
  },
};

export const BATCH = {
  TITLE: "Batch Operations",
  SUB_TITLE: "Operations that work on multiple accounts at once",
  CHURN: {
    TITLE: "Churn Accounts",
    SUB_TITLE:
      "Churn accounts in bulk, this will process immediately and not prorate",
    DESCRIPTION:
      // eslint-disable-next-line max-len
      "This will churn accounts given a list of documentIds and their reasons. This will process immediately and will not prorate.",
    BATCH_DETAILS_TITLE: "Churn Batch Details",
    BATCH_CREATE_TITLE: "Create Churn Batch",
  },
  CREATE_CHURN: {
    uploads: {
      drag_drop_part_1: "Please drag and drop your file here or ",
      drag_drop_part_2: " to upload",
      files_accepted: " Files accepted",
    },
    links: {
      click_here: "Click here",
    },
  },
};

export const GENERAL = {
  NA: "N/A",
};
