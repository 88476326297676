"use client";

import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import { BASE_OLD_PADDOCKS } from "@/utils/common/baseUrls";
import { ROUTE_LABELS } from "@/utils/common/translations";
import { NavLayout } from "@lula-technologies-inc/lux";
import { useSelectedLayoutSegments } from "next/navigation";

export default function NavContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user, logout } = useAuthContext();

  const userActions = {
    onLogoutClick: () => {
      logout();
    },
  };

  const segments = useSelectedLayoutSegments();

  return (
    <NavLayout
      menuItems={
        !!user
          ? [
              {
                icon: "house-blank",
                label: ROUTE_LABELS.HOME,
                href: "/",
                isActive: segments.length === 0,
              },
              {
                icon: "buildings",
                label: ROUTE_LABELS.COMPANIES,
                href: `${BASE_OLD_PADDOCKS}/company`,
              },
              {
                icon: "wagon-covered",
                label: ROUTE_LABELS.VEHICLES,
                href: `${BASE_OLD_PADDOCKS}/vehicle`,
              },
              {
                icon: "car-burst",
                label: ROUTE_LABELS.CLAIMS,
                href: `${BASE_OLD_PADDOCKS}/claims`,
              },
              {
                icon: "ruler",
                label: ROUTE_LABELS.UNDERWRITING,
                href: `${BASE_OLD_PADDOCKS}/underwriting`,
              },
              {
                icon: "money-bill-1-wave",
                label: ROUTE_LABELS.FINANCE,
                href: "/finance",
                isActive: segments[0] === "finance",
              },
              {
                icon: "users",
                label: ROUTE_LABELS.USERS,
                href: `${BASE_OLD_PADDOCKS}/users`,
              },
              {
                icon: "list-check",
                label: ROUTE_LABELS.BATCH,
                href: `/batch`,
              },
            ]
          : [
              {
                icon: "house-blank",
                label: ROUTE_LABELS.HOME,
                href: "/",
                isActive: true,
              },
            ]
      }
      user={user}
      userActions={userActions}
    >
      {children}
    </NavLayout>
  );
}
