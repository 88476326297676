import clsx from "clsx";

const DEFAULT_LOADING_STYLES = ["w-40", "h-36", "rounded-full", "absolute"];

export default function Loading() {
  return (
    <div className={clsx("m-auto", "h-screen", "grid", "place-items-center")}>
      <div className={clsx("flex", "items-center", "justify-center")}>
        <div
          className={clsx(
            ...DEFAULT_LOADING_STYLES,
            "animate-[spin_400ms_linear_infinite]",
            "border-[#333354]",
            "border-l-4",
            "delay-700"
          )}
        />
        <div
          className={clsx(
            ...DEFAULT_LOADING_STYLES,
            "animate-[spin_500ms_linear_infinite]",
            "border-[#333354]",
            "border-l-2",
            "delay-200"
          )}
        />
        <div
          className={clsx(
            ...DEFAULT_LOADING_STYLES,
            "animate-[spin_1s_linear_infinite]",
            "border-[#C6C6D0]",
            "border-l-2",
            "delay-500"
          )}
        />
        <div
          className={clsx(
            ...DEFAULT_LOADING_STYLES,
            "animate-[spin_700ms_linear_infinite]",
            "border-[#C6C6D0]",
            "border-l-4",
            "delay-700"
          )}
        />
        <div
          className={clsx(
            ...DEFAULT_LOADING_STYLES,
            "animate-[spin_1s_linear_infinite]",
            "border-[#FF5701]",
            "border-l-4",
            "delay-1000"
          )}
        />
        <div
          className={clsx(
            ...DEFAULT_LOADING_STYLES,
            "animate-[spin_900ms_linear_infinite]",
            "border-[#FF5701]",
            "border-l-8",
            "delay-500"
          )}
        />
      </div>
    </div>
  );
}
