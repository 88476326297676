"use client";

import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import { SetLoginLocation } from "@/utils/common/login";
import { Button } from "@lula-technologies-inc/lux";
import clsx from "clsx";

export default function Login() {
  const { login } = useAuthContext();

  const handleLogin = async () => {
    SetLoginLocation(window.location.pathname);
    await login();
  };

  return (
    <div
      className={clsx(
        "flex",
        "flex-col",
        "items-center",
        "justify-center",
        "h-screen"
      )}
    >
      <div className={clsx("text-4xl", "font-bold")}>
        Welcome to Paddocks 2.0
      </div>
      <div className={clsx("text-2xl", "font-bold")}>
        Please login to continue
      </div>
      <div className={clsx("mt-4")}>
        <Button type="primary" onClick={handleLogin}>
          Login
        </Button>
      </div>
    </div>
  );
}
