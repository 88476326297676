import { PUBLIC_ENV_VARS } from "./environment";

export const BASE_NEW_PADDOCKS =
  PUBLIC_ENV_VARS.ENVIRONMENT === "production"
    ? "https://paddocks.lula.com"
    : "https://paddocks.staging-lula.com";

export const BASE_OLD_PADDOCKS =
  PUBLIC_ENV_VARS.ENVIRONMENT === "production"
    ? "https://paddocks.lula.is"
    : "https://paddocks.staging-lula.is";

export const BASE_LULASAFE =
  PUBLIC_ENV_VARS.ENVIRONMENT === "production"
    ? "https://lulasafe.lula.is"
    : "https://lulasafe.staging-lula.is";

export const BASE_ONBOARDING =
  PUBLIC_ENV_VARS.ENVIRONMENT === "production"
    ? "https://onboarding.lula.is"
    : "https://onboarding.staging-lula.is";

export const BASE_DOCUMENT_SERVICE =
  PUBLIC_ENV_VARS.ENVIRONMENT === "production"
    ? `https://api.documents.lula.com`
    : `https://api.documents.staging-lula.com`;
