"use client";

import Analytics from "@/components/common/Analytics";
import Styles from "@/components/common/Styles";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthContextProvider } from "@/contexts/common/AuthContextProvider";

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang="en">
      <head>
        <script
          src="https://kit.fontawesome.com/26f92a71e5.js"
          crossOrigin="anonymous"
          async
        ></script>
      </head>
      <body>
        <Analytics />
        <Styles />
        <Auth0Provider
          domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
          clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
          useRefreshTokens
          cacheLocation="localstorage"
          cookieDomain={process.env.NEXT_PUBLIC_AUTH0_COOKIE_DOMAIN!}
        >
          <AuthContextProvider>{children}</AuthContextProvider>
        </Auth0Provider>
      </body>
    </html>
  );
}
