"use client";

import { useEffect } from "react";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { PUBLIC_ENV_VARS } from "@/utils/common/environment";
const { version } = require("../../../package.json");

export default function Analytics() {
  useEffect(() => {
    // TODO MOP-86 add Posthog
    return;
  });

  return (
    <>
      {PUBLIC_ENV_VARS.GOOGLE_ANALYTICS_MEASUREMENT_ID && (
        <GoogleAnalytics
          strategy="lazyOnload"
          gaMeasurementId={PUBLIC_ENV_VARS.GOOGLE_ANALYTICS_MEASUREMENT_ID}
          trackPageViews
        />
      )}
    </>
  );
}
