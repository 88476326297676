"use client";

import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useContext, useEffect, useState } from "react";
import { decodeJwt } from "jose";
import { AppUser } from "@lula-technologies-inc/lux";
import NavContainer from "@/components/common/navContainer";
import Loading from "@/components/common/loading";
import Login from "@/components/common/login";

export type AuthContext = {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  getToken: () => Promise<string | undefined>;
  requestWithAuth: (init?: RequestInit) => Promise<RequestInit>;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: AppUser | undefined;
};

export const AuthContext = createContext<AuthContext | undefined>(undefined);

export const useAuthContext = () => useContext(AuthContext)!;

const useAuth = (): AuthContext => {
  const {
    isLoading: isLoadingAuth0,
    loginWithRedirect,
    logout: logoutAuth0,
    getAccessTokenSilently,
    user: auth0User,
  } = useAuth0();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<AppUser>();

  const login = () =>
    loginWithRedirect({
      authorizationParams: {
        // Hardcoded Aster tenant id
        tenant_id: "8941322c-37f5-4a3f-b49a-f9e06a226122",
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      },
    });

  const logout = async () => {
    await logoutAuth0();
    setIsAuthenticated(false);
    setUser(undefined);
  };

  const getToken = async () => {
    try {
      // Try to get a token from the cache. The sdk may return the
      // cached token even if it is expired.
      const cachedTokens = await getAccessTokenSilently({
        detailedResponse: true,
        authorizationParams: {
          // Hardcoded Aster tenant id
          tenant_id: "8941322c-37f5-4a3f-b49a-f9e06a226122",
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin,
        },
        cacheMode: "on",
      });

      // Verify the token isn't expired
      const payload = decodeJwt(cachedTokens.id_token);
      const epochSecondsNow = Math.floor(Date.now() / 1000);
      if (payload.exp! > epochSecondsNow) {
        setIsAuthenticated(true);
        if (auth0User) {
          setUser({
            id: auth0User.sub!,
            email: auth0User.email!,
            name: auth0User.name ?? auth0User.email!,
          });
        }
        return cachedTokens.id_token;
      }

      // If the token is expired, make a request to Auth0 to get
      // a new identity token using the stored refresh token.
      const freshTokens = await getAccessTokenSilently({
        detailedResponse: true,
        authorizationParams: {
          // Hardcoded Aster tenant id
          tenant_id: "8941322c-37f5-4a3f-b49a-f9e06a226122",
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin,
        },
        cacheMode: "off",
      });
      setIsAuthenticated(true);
      if (auth0User) {
        setUser({
          id: auth0User.sub!,
          email: auth0User.email!,
          name: auth0User.name ?? auth0User.email!,
        });
      }
      return freshTokens.id_token;
    } catch (ex) {
      const err = ex as Error;
      console.log(`Failed to retrieve a valid identity token: ${err?.message}`);
      setIsAuthenticated(false);
      setUser(undefined);
      return undefined;
    }
  };

  const requestWithAuth = async (existing?: RequestInit) => {
    const init: RequestInit = existing != null ? existing : { headers: {} };

    const accessToken = await getToken();
    if (!accessToken) {
      return init;
    }

    init.headers = {
      ...init.headers,
      Authorization: `Bearer ${accessToken}`,
    };

    return init;
  };

  useEffect(() => {
    if (isLoadingAuth0) {
      setIsLoading(true);
      return;
    }
    const effect = async () => {
      await getToken();
      setIsLoading(false);
    };
    effect();
  }, [isLoadingAuth0]);

  return {
    login,
    logout,
    getToken,
    requestWithAuth,
    user,
    isAuthenticated,
    isLoading,
  };
};

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const auth = useAuth();
  return (
    <AuthContext.Provider value={auth}>
      <NavContainer>
        {auth.isLoading ? (
          <Loading />
        ) : auth.user != undefined ? (
          <>{children}</>
        ) : (
          <Login />
        )}
      </NavContainer>
    </AuthContext.Provider>
  );
};
